import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/embed/[category=exists]/[id=assetid]": [~20],
		"/(app)/forgot": [12,[2]],
		"/(app)/(legal)/guidelines": [5,[2,3]],
		"/(app)/join": [~13,[2]],
		"/(app)/(legal)/license": [6,[2,3]],
		"/(app)/login": [~14,[2]],
		"/(app)/(legal)/privacy": [7,[2,3]],
		"/(app)/reset": [~15,[2]],
		"/(app)/studio": [16,[2]],
		"/(app)/subscribe": [17,[2]],
		"/(app)/support": [18,[2]],
		"/(app)/(legal)/terms": [8,[2,3]],
		"/(app)/tools": [19,[2]],
		"/(app)/[user=username]": [~11,[2]],
		"/(app)/[category=exists]": [~9,[2]],
		"/(app)/[category=exists]/[slug=assetid]": [~10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';